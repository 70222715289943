<template>
	<ion-grid>
		<ion-row>
			<!-- Errors -->
			<div v-if="isError === true">
				<div v-for="error in errors" :key="error">
					<div v-for="el in error" :key="el">
						<ion-item v-if="el == 'validation.image64'">
							<div tabindex="0"></div><!-- bug fix for safari 14 -->
							<ion-text color="danger">Please upload a JPG or PNG.</ion-text>
						</ion-item>
					</div>
				</div>
			</div>
		</ion-row>

		<ion-row class="ion-justify-content-center ion-padding-bottom">
			<!-- Image has not been uploaded and authUser profile photo path is null -->
			<ion-avatar v-if="store.authUser.profile_photo_path === null && imageUrl === null" class="ion-text-center">
				<ion-img  src="https://artistwavesoftware.s3-us-west-1.amazonaws.com/users/default.jpg" />
			</ion-avatar>
			<!-- Image has been uploaded and authUser profile photo path is null -->
			<ion-avatar v-if="imageUrl != null && store.authUser.profile_photo_path  === null" class="ion-text-center">
				<ion-img :src="imageUrl" />
			</ion-avatar>
			<!-- Image has not been uploaded and authUser profile photo path is not null -->
			<ion-avatar v-if="imageUrl === null && store.authUser.profile_photo_path  != null" class="ion-text-center">
				<ion-img :src="store.authUser.profile_photo_path" />
			</ion-avatar>
			<!-- Image has not been uploaded and authUser profile photo path is not null -->
			<ion-avatar v-if="store.authUser.profile_photo_path != null && imageUrl != null" class="ion-text-center">
				<ion-img :src="imageUrl" />
			</ion-avatar>
		</ion-row>
		
		<ion-row class="ion-justify-content-center ion-padding-bottom">
			<p>
				<ion-text v-if="imageUrl === null">Upload a profile photo</ion-text>
				<ion-text v-if="imageUrl != null">Save profile photo</ion-text>
			</p>
		</ion-row>
		<ion-row v-if="imageUrl === null" class="ion-justify-content-center ion-padding-bottom">	
			<ion-button @click="takePicture()">
				<ion-icon :icon="image"></ion-icon>
			</ion-button>
		</ion-row>
		<ion-row v-if="imageUrl != null" class="ion-justify-content-center ion-padding-bottom">
			<ion-button @click="uploadImage()">Save</ion-button>
			<ion-button color="danger" v-if="imageUrl != null" @click="resetImage()">
				<ion-icon :icon="trash"></ion-icon>
			</ion-button>
		</ion-row>
	</ion-grid>
</template>

<script>
import { IonGrid, IonRow, IonItem, IonText, IonAvatar, IonButton, IonImg, IonIcon, loadingController, toastController } from '@ionic/vue';
import { image, trash } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { Plugins, CameraSource, CameraResultType } from "@capacitor/core";
const { Camera } = Plugins;
//import { usePhotoGallery } from '@/composables/usePhotoGallery';
import apiClient from '../services/api';

export default defineComponent({
	name: 'ProfilePhoto',
	props: ['store'],
	components: {
		IonGrid, IonRow, IonItem, IonText, IonAvatar, IonButton, IonImg, IonIcon
	},
	data() {
		return {
			imageUrl: null,
			//profilePhoto: null,
		};
	},
	setup(props) {
		const timeout = { default: 3000 }
		const isError = ref(false);
		const errors = ref([]);
		const toastMessage = ref(null);

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() { loading.dismiss() }, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
					{
						icon: 'close',
						role: 'cancel',
						handler: () => {}
					}
				]
			})
			return toast.present();
		}

		function resetForm() {
			isError.value = false
			errors.value = []
		}

		function uploadImage() {
			presentLoading()
			// Send auth token
			apiClient.post('/api/post/profile-photo', {
					image: this.imageUrl
				},
				{
					headers: {
						'Authorization':  `Bearer ${props.store.authToken}`
					}
				}).then(response => {
					console.log(response)
					if(response.status === 200) {
						// Success
						if(response.data.message == 'success') {
							localStorage.setItem('authUser', JSON.stringify(response.data.user));
							this.dataUp(response.data.user);
							toastMessage.value = 'You have updated your profile photo.'
							openToast()
							resetForm()
						}
						// Fail Validation
						if(response.data.message == 'fail') {
							toastMessage.value = 'Something went wrong.'
							openToast()          
						}
						// Fail Validation
						if(response.data.message == 'fail-validation') {
							toastMessage.value = 'Something went wrong.'
							openToast()
							isError.value = true
							errors.value = response.data.errors            
						}
						// Fail Auth
						if(response.data.message == 'fail-auth') {
							toastMessage.value = 'You must be signed in to update your profile.'
							openToast()
						}
					}
				}).catch(error => {
					console.log(error)  
					// credentials didn't match

					isError.value = true
					errors.value = error.response.data.errors
				});
		}
		return {
			uploadImage, image, trash, presentLoading, isError, errors
		}
	},
	methods: {
		async takePicture() {
			let isAvailable = true;
			if (!isAvailable) {
				// Have the user upload a file instead
				alert("No Camera Aailable");
			} else {
				// Otherwise, make the call:
				try {
					const image = await Camera.getPhoto({
						quality: 90,
						allowEditing: true,
						resultType: CameraResultType.DataUrl,
						source: CameraSource.Prompt
					});
					console.log("image", image);
					// image.base64_data will contain the base64 encoded result as a JPEG, with the data-uri prefix added
					this.imageUrl = image.dataUrl;
					// can be set to the src of an image now
					console.log(image);
				} catch (e) {
					console.log("error", e);
				}
			}
		},
		resetImage: function() {
			this.imageUrl = null
		},
		dataUp: function(user) {
			var componentData = {
				dataType: 'user',
				data: user
			}

			this.$emit('component-data', componentData)
		}
	},

});
</script>
<style scoped>
ion-avatar {
  width:180px !important;
  height: 180px !important;
  max-width: 180px !important;  
  max-height: 180px !important; 
}
</style>